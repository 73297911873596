import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function Bubble({
    label,
    value,
    clicks,
    inViews,
    onClick,
    onMouseEnter,
    onMouseLeave,
    isDropdown,
    svgRef,
    hoverColor,
    activeColor,
    bgColor,
    x,
    y,
    r,
    style,
}) {
    const defaultTextSize = 30; // TODO: move to prop?
    const el = useRef();
    const [fontSize, setFontSize] = useState(defaultTextSize);
    const [opacity, setOpacity] = useState(0);

    // state of the color of the circle/bubble default: BG_COLOR, PURPLE_DARKER, PURPLE_DARK
    const [color, setColor] = useState(bgColor);

    useEffect(() => {
        /*
            We do this so that the text of a bubble is contained within it.
        */
        setFontSize(
            Math.max(
                Math.min(
                    defaultTextSize,
                    ((2 * r - 8) / el.current.getComputedTextLength()) * 24,
                ),
                0,
            ),
        );
        /*
            We set opacity to avoid a flash of large text.
        */
        setOpacity(1);
    }, [el, defaultTextSize, r]);

    return (
        <>
            <circle
                r={r}
                transform={`translate(${x}, ${y})`}
                fill={color}
                style={isDropdown ? { cursor: 'pointer' } : {}}
                onClick={(event) => {
                    onClick(
                        event,
                        svgRef,
                        label,
                        value,
                        clicks,
                        inViews,
                        setColor,
                        activeColor,
                        isDropdown,
                    );
                }}
                onMouseEnter={() =>
                    onMouseEnter(setColor, hoverColor, isDropdown)
                }
                onMouseLeave={() => onMouseLeave(setColor, bgColor, isDropdown)}
            />
            <text
                textAnchor="middle"
                ref={el}
                transform={`translate(${x}, ${y})`}
                pointerEvents="none"
                style={{
                    ...style,
                    fontSize: `${fontSize}px`,
                    opacity,
                }}
                fill="#FFFFFF"
            >
                <tspan dominantBaseline="middle">{label}</tspan>
            </text>
        </>
    );
}

Bubble.defaultProps = {
    value: undefined,
    clicks: undefined,
    onClick: () => {},
    onMouseEnter: () => {},
    onMouseLeave: () => {},
    isDropdown: false,
    svgRef: undefined,
    hoverColor: undefined,
    activeColor: undefined,
    style: {},
};

Bubble.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.number,
    clicks: PropTypes.number,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    isDropdown: PropTypes.bool,
    svgRef: PropTypes.object,
    hoverColor: PropTypes.string,
    activeColor: PropTypes.string,
    bgColor: PropTypes.string.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    r: PropTypes.number.isRequired,
    style: PropTypes.object,
};

export default Bubble;
