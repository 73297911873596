import React, { useContext, useEffect, useState } from 'react';
import { api, AbortError, cleanUrl, validUrl } from 'js/utils';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Container from 'js/components/container/container';
import Drawer from 'js/components/drawer/drawer';
import DrawerFooter from 'js/components/drawer/footer';
import Form from 'js/components/form/form';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Input from 'js/components/input/input';
import Layout from 'js/components/layout/layout';
import Spinner from 'js/components/spinner/spinner';
import BubbleKeywords from './bubble-chart';
import ContextDetailContext from '../../contexts/context-detail.context';
import styles from './page-analyzer-drawer.module.scss';

// TODO: add state for the url and results

const TIMEOUT_LIMIT = 120000; // 2 mins

const UnableToAccessPage = ({ url, retry }) => (
    <Alert
        theme="danger"
        title="Unable To Access Page"
        actionItems={<Button onClick={retry}>Retry</Button>}
    >
        <p>4D was unable to access a page with the URL &apos;{url}&apos;.</p>
        <p>
            Please check that the URL is correct and is accessible without
            having to login before trying again.
        </p>
    </Alert>
);

const NoKeywordsFound = () => (
    <Alert theme="empty" title="No Keywords Found">
        <p>
            No keywords could be found for this page. Please try again with
            another URL.
        </p>
    </Alert>
);

const LoadingError = ({ retry }) => (
    <Alert
        theme="danger"
        title="There was an unexpected error when loading data"
        actionItems={<Button onClick={retry}>Retry</Button>}
    >
        <p>
            Hopefully it is only a temporary issue. Please try again in a few
            moments.
        </p>
    </Alert>
);

const GetStarted = () => (
    <Alert theme="empty" title="Learn How To Target Or Block A Page">
        <p>
            Find out which keywords you could use to target or block a specific
            page and other pages like it.
        </p>
        <p>
            Enter the URL of a page in the input above and hit the ‘Analyze
            Page’ button to get started.
        </p>
    </Alert>
);

export default function PageAnalyzerDrawer({ onClose }) {
    const [url, setUrl] = useState('');
    const [activeUrl, setActiveUrl] = useState('');
    const [urlKeywords, setUrlKeywords] = useState(null);
    const [taskStart, setTaskStart] = useState();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const {
        context: {
            current: { rules },
        },
    } = useContext(ContextDetailContext);

    useEffect(() => {
        let taskInterval;
        const updateTask = async (t) => {
            if (!t.id || t.state === 'Done') {
                setLoading(false);
                return;
            }
            try {
                const newTask = await api().tasks.retrieve(t.id);
                if (
                    newTask.state === 'Error' ||
                    Date.now() - taskStart >= TIMEOUT_LIMIT
                ) {
                    clearInterval(taskInterval);
                    const error = {
                        results: 'there was a problem during url processing',
                    };
                    throw error;
                } else if (newTask.state === 'Done') {
                    clearInterval(taskInterval);
                    setUrlKeywords(
                        JSON.parse(
                            newTask.outputs.find(
                                (i) => i.variable === 'keywords',
                            )?.value,
                        )?.map((i) => ({ entity: i.name, score: i.score })),
                    );
                    setLoading(false);
                    setErrors({});
                }
            } catch (err) {
                if (err instanceof AbortError) return;
                setErrors(err);
                setLoading(false);
            }
        };
        const createTask = async () => {
            try {
                if (!validUrl(cleanUrl(activeUrl))) {
                    const error = { detail: 'bad url' };
                    throw error;
                }
                setErrors({});
                setLoading(true);
                const newTask = await api().tasks.create({
                    job: 'page_explore',
                    inputs: [
                        {
                            variable: 'url',
                            value: cleanUrl(activeUrl),
                        },
                    ],
                });
                setTaskStart(Date.now());
                taskInterval = setInterval(() => updateTask(newTask), 2000);
            } catch (err) {
                if (err instanceof AbortError) return;
                setErrors(err);
                setLoading(false);
            }
        };
        if (activeUrl) {
            createTask();
        } else {
            setLoading(false);
            setErrors({});
        }
        return () => {
            clearInterval(taskInterval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeUrl]);

    return (
        <Drawer onClose={onClose}>
            <Layout
                header={
                    <div className={styles.header}>
                        <Container size="medium">
                            <Box padding={['small', 0]}>
                                <Form onSubmit={() => setActiveUrl(url)}>
                                    <Row gutter="small">
                                        <Col>
                                            <Input
                                                focusOnShow
                                                onChange={(e) => {
                                                    setUrl(e.target.value);
                                                    setErrors({});
                                                }}
                                                value={url}
                                            />
                                        </Col>
                                        <Col span="auto">
                                            <Button type="submit">
                                                Analyze Page
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Box>
                        </Container>
                    </div>
                }
                footer={
                    <DrawerFooter>
                        <Container size="medium">
                            <Box padding={['small', 0]}>
                                <Row justifyContent="flex-end">
                                    <Col span="auto">
                                        <Button onClick={onClose}>Done</Button>
                                    </Col>
                                </Row>
                            </Box>
                        </Container>
                    </DrawerFooter>
                }
                isFooterSticky
            >
                <Container
                    size="medium"
                    style={{
                        minHeight: '100%',
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        alignItems: 'stretch',
                        alignContent: 'center',
                    }}
                >
                    <PageAnalyzerContent
                        loading={loading}
                        errors={errors}
                        urlKeywords={urlKeywords}
                        rules={rules}
                        url={url}
                        retry={() => setActiveUrl(url)}
                    />
                </Container>
            </Layout>
        </Drawer>
    );
}

const Wrapper = ({ children }) => (
    <div className={styles.bubble__title}>{children}</div>
);

const PageAnalyzerContent = ({
    loading,
    errors,
    urlKeywords,
    rules,
    url,
    retry,
}) => {
    if (loading) {
        return (
            <Wrapper>
                <Spinner
                    size="large"
                    message="Loading"
                    color={['gray', 'dark']}
                    isCentered
                />
            </Wrapper>
        );
    }
    if (errors.detail) {
        return (
            <Wrapper>
                <LoadingError retry={retry} />
            </Wrapper>
        );
    }
    if (errors.results) {
        return (
            <Wrapper>
                <UnableToAccessPage url={url} retry={retry} />
            </Wrapper>
        );
    }
    if (urlKeywords === null) {
        return (
            <Wrapper>
                <GetStarted />
            </Wrapper>
        );
    }
    if (!urlKeywords.length) {
        return (
            <Wrapper>
                <NoKeywordsFound />
            </Wrapper>
        );
    }
    return <BubbleKeywords bubbleData={urlKeywords} rules={rules} />;
};
