import classNames from 'classnames';
import * as d3 from 'd3';
import forceBoundary from 'd3-force-boundary';
import Box from 'js/components/box/box';
import Container from 'js/components/container/container';
import DropdownMenu from 'js/components/dropdown-menu/dropdown-menu';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import RuleSelector from 'js/components/dropdown-menu/rule-selector';
import Text from 'js/components/text/text';
import ruleTypes from 'js/enums/rule-types.enum';
import { cleanString } from 'js/utils';
import React, {
    Component,
    useContext,
    useEffect,
    useRef,
    useState,
    Fragment,
} from 'react';
import ContextDetailContext from '../../contexts/context-detail.context';
import styles from './page-analyzer-drawer.module.scss';

const BubbleKeywordsMenu = ({ keyword, onClose }) => {
    const {
        context: {
            current: { rules },
            setRules,
        },
        topics: { groups: topicGroups },
    } = useContext(ContextDetailContext);

    return (
        <RuleSelector
            keyword={cleanString(keyword)}
            topicGroups={topicGroups}
            rules={rules}
            onChangeRules={(newRules) => {
                setRules(newRules);
                onClose();
            }}
        />
    );
};

const color = d3
    .scaleLinear()
    .domain([0, 0.05, 0.1, 0.15, 1])
    .range(['#985CFF', '#7254E8', '#6973FF', '#5CBDFF', '#5485E8'])
    .interpolate(d3.interpolateHcl);

const BubbleLegend = () => (
    <Box padding={['small', 0]}>
        <Container>
            <Row alignItems="center" gutter="smaller" justifyContent="flex-end">
                <Col span="auto">
                    <Text size="base" weight="bold" color={['gray', 'dark']}>
                        <p>Low Relevance</p>
                    </Text>
                </Col>
                <Col span="auto">
                    <svg
                        viewBox="0 0 100 100"
                        style={{ height: '15px', width: '15px' }}
                    >
                        <g>
                            <circle fill={color(0)} r="50" cx="50" cy="50" />
                        </g>
                    </svg>
                </Col>
                <Col span="auto">
                    <svg
                        viewBox="0 0 100 100"
                        style={{ height: '20px', width: '20px' }}
                    >
                        <g>
                            <circle fill={color(0.1)} r="50" cx="50" cy="50" />
                        </g>
                    </svg>
                </Col>
                <Col span="auto">
                    <svg
                        viewBox="0 0 100 100"
                        style={{ height: '25px', width: '25px' }}
                    >
                        <g>
                            <circle fill={color(0.2)} r="50" cx="50" cy="50" />
                        </g>
                    </svg>
                </Col>
                <Col span="auto">
                    <Text size="base" weight="bold" color={['gray', 'dark']}>
                        <p>High Relevance</p>
                    </Text>
                </Col>
            </Row>
        </Container>
    </Box>
);

const BubbleText = ({ name, transform, radius, isBlocked }) => {
    const defaultTextSize = 30;
    const el = useRef();
    const [fontSize, setFontSize] = useState(defaultTextSize);
    const [opacity, setOpacity] = useState(0);
    useEffect(() => {
        /*
            We do this so that the text of a bubble is contained within it.
        */
        setFontSize(
            Math.max(
                Math.min(
                    defaultTextSize,
                    ((2 * radius - 8) / el.current.getComputedTextLength()) *
                        24,
                ),
                0,
            ),
        );
        /*
            We set opacity to avoid a flash of large text.
        */
        setOpacity(1);
    }, [el, defaultTextSize, radius]);
    const classes = [];
    if (isBlocked) {
        classes.push(styles['bubble__text--blocked']);
    }
    return (
        <text
            className={classNames(classes)}
            ref={el}
            transform={transform}
            pointerEvents="none"
            dy=".35em"
            style={{ fontSize: `${fontSize}px`, opacity }}
        >
            {name}
        </text>
    );
};

export default class BubbleKeywords extends Component {
    constructor(props) {
        super(props);
        this.chartContainer = '';

        this.state = {
            activeKeyword: '',
            menuCoords: '',
            bubbles: [],
            showMenu: false,
            width: 0,
            height: 0,
            forceUpdate: 0,
        };
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClick);
        this.createChart();
    }

    componentDidUpdate() {
        const { bubbleData } = this.props;
        const { bubbles } = this.state;
        if (bubbleData.length && !bubbles.length) {
            this.createChart();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick);
    }

    handleClick(evt) {
        if (this.chartContainer && !this.chartContainer.contains(evt.target)) {
            this.setShowMenu(false);
        }
    }

    setActiveKeyword = (activeKeyword) => {
        this.setState({ activeKeyword });
    };

    setMenuCoords = (menuCoords) => {
        this.setState({ menuCoords });
    };

    setShowMenu = (showMenu) => {
        this.setState({ showMenu });
    };

    setWidth = (width) => {
        this.setState({ width });
    };

    setHeight = (height) => {
        this.setState({ height });
    };

    setBubbles = (bubbles) => {
        this.setState({ bubbles });
    };

    createChart = () => {
        const { bubbles, forceUpdate } = this.state;
        const { bubbleData } = this.props;
        if (this.chartContainer && !bubbles.length && bubbleData.length) {
            const thisWidth = this.chartContainer.getBoundingClientRect().width;
            const thisHeight =
                this.chartContainer.getBoundingClientRect().height;
            const packedNodes = ((data) =>
                d3.pack().size([thisWidth, thisHeight]).padding(3)(
                    d3.hierarchy({ children: data }).sum((d) => d.value),
                ))(
                bubbleData
                    .map(({ entity, score }) => ({
                        name: entity,
                        value: score - 0.05,
                    }))
                    .filter((item) => item.value > 0),
            ).children;
            const randomizedNodes = packedNodes.map((node) => ({
                ...node,
                x: thisWidth * 0.1 + Math.random() * thisWidth * 0.9,
                y: thisHeight * 0.1 + Math.random() * thisHeight * 0.9,
            }));
            const forceStrength = 0.02;
            d3.forceSimulation()
                .force(
                    'x',
                    d3
                        .forceX()
                        .strength(forceStrength)
                        .x(thisWidth / 2),
                )
                .force(
                    'y',
                    d3
                        .forceY()
                        .strength(forceStrength)
                        .y(thisHeight / 2),
                )
                .force(
                    'collision',
                    d3.forceCollide().radius((d) => d.r + 1),
                )
                .force(
                    'boundary',
                    forceBoundary(
                        thisWidth * 0.1,
                        thisHeight * 0.1,
                        thisWidth * 0.9,
                        thisHeight * 0.9,
                    ).strength(forceStrength),
                )
                .nodes(randomizedNodes)
                .on('tick', () => {
                    /*
                        We're doing something dirty here.
                        d3.force does not return any values, it mutates the `nodes` array.
                        We're using React to render our graphic, but we need to
                        force it to update every tick of the simulation as it will
                        not react to `nodes` being mutated.
                    */
                    this.setState({ forceUpdate: forceUpdate + 1 });
                });
            this.setBubbles(randomizedNodes);
            this.setWidth(thisWidth);
            this.setHeight(thisHeight);
        }
    };

    render() {
        const { width, height, menuCoords, activeKeyword, showMenu, bubbles } =
            this.state;

        const { rules } = this.props;

        const includedKeywords = rules
            .filter((item) => item.aggregation === ruleTypes.INCLUDED)
            .map((item) => item.keywords)
            .flat();

        const excludedKeywords = rules
            .filter((item) => item.aggregation === ruleTypes.EXCLUDED)
            .map((item) => item.keywords)
            .flat();

        return (
            <div className={styles.bubble__wrapper}>
                <div className={styles.bubble__title}>
                    <BubbleLegend />
                </div>
                <div
                    className={styles['bubble__simple-chart']}
                    ref={(ref) => {
                        this.chartContainer = ref;
                    }}
                >
                    <svg
                        viewBox={`-${width / 2} -${
                            height / 2
                        } ${width} ${height}`}
                        onClick={(evt) => {
                            if (evt.currentTarget === evt.target) {
                                this.setShowMenu(false);
                            }
                            const el =
                                evt.currentTarget.getBoundingClientRect();
                            const x = evt.clientX - el.left;
                            const y = evt.clientY - el.top;
                            this.setMenuCoords({ x, y });
                        }}
                    >
                        <g textAnchor="middle">
                            {bubbles.map((bubble) => {
                                const transform = `translate(${
                                    bubble.x - width / 2
                                }, ${bubble.y - height / 2})`;
                                const bubbleClasses = [styles.bubble__bubble];
                                if (
                                    includedKeywords.includes(
                                        cleanString(bubble.data.name),
                                    )
                                ) {
                                    bubbleClasses.push(
                                        styles['bubble__bubble--targeted'],
                                    );
                                } else if (
                                    excludedKeywords.includes(
                                        cleanString(bubble.data.name),
                                    )
                                ) {
                                    bubbleClasses.push(
                                        styles['bubble__bubble--blocked'],
                                    );
                                }
                                return (
                                    <Fragment key={bubble.data.name}>
                                        <circle
                                            className={classNames(
                                                bubbleClasses,
                                            )}
                                            fill={color(bubble.data.value)}
                                            r={bubble.r}
                                            transform={transform}
                                            onClick={() => {
                                                this.setActiveKeyword(
                                                    cleanString(
                                                        bubble.data.name,
                                                    ),
                                                );
                                                this.setShowMenu(!showMenu);
                                            }}
                                        />
                                        <BubbleText
                                            name={bubble.data.name}
                                            radius={bubble.r}
                                            transform={transform}
                                            isBlocked={excludedKeywords.includes(
                                                cleanString(bubble.data.name),
                                            )}
                                        />
                                    </Fragment>
                                );
                            })}
                        </g>
                    </svg>

                    <div
                        style={{
                            position: 'absolute',
                            top: `${menuCoords.y}px`,
                            left: `${menuCoords.x}px`,
                        }}
                        key={`${menuCoords.x},${menuCoords.y}`}
                    >
                        <DropdownMenu
                            content={
                                <BubbleKeywordsMenu
                                    keyword={activeKeyword}
                                    onClose={() => {
                                        this.setShowMenu(false);
                                        this.setActiveKeyword('');
                                    }}
                                />
                            }
                            showMenu={!!showMenu}
                        >
                            <div />
                        </DropdownMenu>
                    </div>
                </div>
            </div>
        );
    }
}
