import React from 'react';
import DropdownMenu from 'js/components/dropdown-menu/dropdown-menu';
import Box from 'js/components/box/box';
import Sparkline from 'js/components/sparkline/sparkline';
import Text from 'js/components/text/text';
import styles from './dropdown-tooltip.module.scss';

const DropdownTooltipContent = ({ dropdownData: { label, data } }) => (
    <>
        <Text color={['gray', 'darkest']} size="large" weight="bold">
            {label}
        </Text>

        {data.map((dataValue) => {
            const { title, value, isDelta, comparedValue } = dataValue;

            return (
                <Box key={title} margin={['small', 0, 0]}>
                    <Text color={['gray', 'dark']} size="small" weight="base">
                        {title}
                    </Text>

                    <Sparkline
                        value={value}
                        comparedValue={comparedValue}
                        isDelta={isDelta}
                    />
                </Box>
            );
        })}
    </>
);

const DropdownTooltip = ({
    dropdownCoords,
    setToggleDropdown,
    toggleDropdown,
    dropdownData,
}) => (
    <div
        style={{
            position: 'absolute',
            top: `${dropdownCoords.y}px`,
            left: `${dropdownCoords.x}px`,
        }}
        key={`${dropdownCoords.x},${dropdownCoords.y}`}
    >
        <DropdownMenu
            className={styles['content-container']}
            onHide={() => setToggleDropdown(false)}
            content={<DropdownTooltipContent dropdownData={dropdownData} />}
            showMenu={toggleDropdown}
        />
    </div>
);

export default DropdownTooltip;
