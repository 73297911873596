import React, { useEffect, useRef, useState } from 'react';
import forceBoundary from 'd3-force-boundary';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import Box from 'js/components/box/box';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Text from 'js/components/text/text';
import Bubble from './bubble';
import styles from './bubble-chart.module.scss';

const createD3Nodes = ({ width, height, data, minValue }) => {
    if (!width || !height) return [];
    return d3.pack().size([width, height]).padding(3)(
        d3
            .hierarchy({ children: data })
            .sum((d) => d.value - minValue * 0.95)
            .sort(() => (Math.random() > 0.5 ? 1 : -1)),
    ).children;
};

const positionNodes = ({ width, height, nodes, viewport }) => {
    const [viewXMin, viewYMin] = viewport;
    const treeMapLayout = d3
        .treemap()
        .tile(d3.treemapSquarify.ratio(1))
        .size([width, height])
        .round(true)
        .padding(1)(
        d3
            .hierarchy({ children: nodes.map((i) => i.data) })
            .sum((s) => s.value),
    );

    const newNodes = nodes.map((node, j) => ({
        ...node,
        x: width * viewXMin + treeMapLayout.children[j].x0 + node.r,
        y: height * viewYMin + treeMapLayout.children[j].y0 + node.r,
    }));

    return newNodes;
};

// eslint-disable-next-line no-unused-vars
const addD3ForceNodes = ({
    width,
    height,
    nodes,
    viewport,
    isAnimated,
    callback,
}) => {
    const [viewXMin, viewYMin, viewX, viewY] = viewport;
    const maxR = Math.max(...nodes.map((i) => i.r));
    const simulation = d3
        .forceSimulation(nodes)
        .force(
            'collision',
            d3
                .forceCollide()
                .radius((d) => d.r + 1)
                .strength(1.5),
        )
        .force(
            'boundary',
            forceBoundary(
                width * viewXMin + maxR,
                height * viewYMin + maxR,
                width * viewX - maxR,
                height * viewY - maxR,
            )
                .hardBoundary(true)
                .strength(0.05),
        )
        .velocityDecay(0.65)
        .alphaDecay(0.01);

    if (!isAnimated) {
        simulation.tick(300).stop();
    }

    simulation.on('tick', callback);

    return nodes;
};

const Legend = ({ legend: { title, lowLabel, highLabel }, colorScale }) => {
    const color = colorScale.domain([0, 1]);
    return (
        <Box padding="base">
            <Row alignItems="center" justifyContent="space-between">
                <Col span="auto">
                    {title && (
                        <Text size="large" color={['gray', 'dark']}>
                            {title}
                        </Text>
                    )}
                </Col>

                <Col span="auto">
                    <Row alignItems="center" gutter="smaller">
                        {lowLabel && (
                            <Col span="auto">
                                <Text color={['gray', 'dark']}>{lowLabel}</Text>
                            </Col>
                        )}

                        {[
                            { width: '12', color: color(0) },
                            { width: '17', color: color(0.5) },
                            { width: '22', color: color(1) },
                        ].map((row) => (
                            <Col span="auto" key={row.width}>
                                <svg width={row.width} viewBox="0 0 100 100">
                                    <circle
                                        fill={row.color}
                                        r="50"
                                        cx="50"
                                        cy="50"
                                    />
                                </svg>
                            </Col>
                        ))}

                        {highLabel && (
                            <Col span="auto">
                                <Text color={['gray', 'dark']}>
                                    {highLabel}
                                </Text>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
        </Box>
    );
};

function BubbleChart({
    data,
    legend,
    viewport,
    isAnimated,
    dropdownTooltip,
    onClick,
    onMouseEnter,
    onMouseLeave,
    hoverColor,
    activeColor,
    customD3ColorScale,
}) {
    const chartContainerRef = useRef();
    const svgRef = useRef();
    const legendRef = useRef();

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [legendHeight, setLegendHeight] = useState(0);
    const [chartData, setChartData] = useState([]);
    const setTickState = useState(false)[1];

    const bubblesHeight = height - legendHeight;
    const maxValue = Math.max(...data.map((i) => i.value));
    const minValue = Math.min(...data.map((i) => i.value));

    useEffect(() => {
        const handleResize = () => {
            if (chartContainerRef.current && !width && !height) {
                setWidth(
                    chartContainerRef.current.getBoundingClientRect().width,
                );
                setHeight(
                    chartContainerRef.current.getBoundingClientRect().height,
                );
            }

            if (legendRef.current) {
                setLegendHeight(
                    legendRef.current.getBoundingClientRect().height,
                );
            }
            if (chartData.length !== data.length) {
                const bubbleChart = createD3Nodes({
                    width,
                    height: bubblesHeight,
                    data,
                    minValue,
                });
                const chartRandom = positionNodes({
                    width,
                    height: bubblesHeight,
                    nodes: bubbleChart,
                    viewport,
                });
                const chartForce = addD3ForceNodes({
                    width,
                    height: bubblesHeight,
                    nodes: chartRandom,
                    callback: () => {
                        setTickState((curr) => !curr);
                    },
                    viewport,
                    isAnimated,
                });
                setChartData(chartForce);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [
        bubblesHeight,
        chartData.length,
        data,
        height,
        isAnimated,
        minValue,
        setTickState,
        viewport,
        width,
    ]);

    const legendComponent = (
        <div ref={legendRef} className={styles.legend}>
            <Legend legend={legend} colorScale={customD3ColorScale} />
        </div>
    );

    const [viewXMin, viewYMin, viewX, viewY] = viewport;

    return (
        <div className={styles.wrapper}>
            {legend && legend.position === 'top' && legendComponent}
            <div className={styles.container} ref={chartContainerRef}>
                <svg
                    ref={svgRef}
                    viewBox={`${width * viewXMin} ${bubblesHeight * viewYMin} ${
                        width * (viewX - viewXMin)
                    } ${bubblesHeight * (viewY - viewYMin)}`}
                >
                    <g textAnchor="middle">
                        {chartData.map(
                            ({
                                data: { label, value, clicks, inViews },
                                x,
                                y,
                                r,
                            }) => (
                                <Bubble
                                    label={label}
                                    value={value}
                                    clicks={clicks}
                                    inViews={inViews}
                                    onClick={onClick}
                                    onMouseEnter={onMouseEnter}
                                    onMouseLeave={onMouseLeave}
                                    isDropdown={!!dropdownTooltip}
                                    svgRef={svgRef}
                                    hoverColor={hoverColor}
                                    activeColor={activeColor}
                                    bgColor={customD3ColorScale(
                                        value / maxValue,
                                    )}
                                    key={`${label}-${value}`}
                                    x={x}
                                    y={y}
                                    r={r}
                                    style={{
                                        fontVariant: 'normal',
                                        fontWeight: 'bold',
                                        fontFamily: 'Arial',
                                    }}
                                />
                            ),
                        )}
                    </g>
                </svg>
                {dropdownTooltip}
            </div>
            {legend && legend.position === 'bottom' && legendComponent}
        </div>
    );
}

BubbleChart.defaultProps = {
    customD3ColorScale: d3.scaleSequential(d3.interpolateSpectral),
    legend: undefined,
    viewport: [0, 0, 1, 1],
    isAnimated: false,
};

BubbleChart.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.number,
        }),
    ).isRequired,
    customD3ColorScale: PropTypes.func,
    legend: PropTypes.shape({
        lowLabel: PropTypes.string,
        highLabel: PropTypes.string,
        title: PropTypes.string,
        position: PropTypes.oneOf(['top', 'bottom']),
    }),
    viewport: PropTypes.arrayOf(PropTypes.number),
    isAnimated: PropTypes.bool,
};

export default BubbleChart;
