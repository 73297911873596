import React, { useState } from 'react';
import * as d3 from 'd3';
import Box from 'js/components/box/box';
import BubbleChart from 'js/components/bubble-chart/bubble-chart';
import DropdownTooltip from '../../../components/dropdown-tooltip/dropdown-tooltip';
import styles from './keywords-panel.module.scss';

export default function KeywordBubblesSection({
    report: { keywords, impressions, clicks, in_views: inViews },
}) {
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [dropdownCoords, setDropdownCoords] = useState(true);
    const [dropdownData, setDropdownData] = useState({});

    const onClick = (
        event,
        svgRef,
        label,
        bubbleImpressions,
        bubbleClicks,
        bubbleInViews,
        setColor,
        activeColor,
        isDropdown,
    ) => {
        if (!isDropdown) return;
        if (event.currentTarget === event.target) {
            setToggleDropdown(false);
        }
        const svgElement = svgRef.current.getBoundingClientRect();
        const xAxis = event.clientX - svgElement.left;
        const yAxis = event.clientY - svgElement.top;
        let data = [
            {
                title: 'Impressions',
                value: bubbleImpressions,
                isDelta: false,
                comparedValue: impressions,
            },
            {
                title: 'Clicks',
                value: bubbleClicks,
                isDelta: false,
                comparedValue: clicks,
            },
            {
                title: 'CTR',
                value: bubbleClicks / bubbleImpressions,
                isDelta: true,
                comparedValue: clicks / impressions,
            },
            {
                title: 'Viewable Impressions',
                value: bubbleInViews,
                isDelta: false,
                comparedValue: inViews,
            },
            {
                title: 'View Rate',
                value: bubbleInViews / bubbleImpressions,
                isDelta: true,
                comparedValue: inViews / impressions,
            },
        ];

        if (!inViews) {
            data = data.slice(0, -2);
        }

        setDropdownData({
            label,
            data,
        });
        setColor(activeColor);
        setDropdownCoords({ x: xAxis, y: yAxis });
        setToggleDropdown(true);
    };

    const onMouseEnter = (setColor, color, isDropdown) =>
        isDropdown && setColor(color);

    const onMouseLeave = (setColor, color, isDropdown) => {
        if (!isDropdown) return;
        setColor(color);
        setToggleDropdown(false);
    };

    const bubblesData = [...keywords]
        .sort((a, b) => b.impressions - a.impressions)
        .slice(0, 50)
        .map((keyword) => ({
            label: keyword.name,
            value: keyword.impressions,
            clicks: keyword.clicks,
            inViews: keyword.in_views,
        }));

    return (
        <Box margin={['base', 0]}>
            <div className={styles['bubble-chart-container']}>
                <BubbleChart
                    data={bubblesData}
                    customD3ColorScale={d3
                        .scaleSequential()
                        .interpolator(d3.interpolateRgb('#6673FF', '#6673FF'))}
                    legend={{
                        lowLabel: 'Fewer Impressions',
                        highLabel: 'More Impressions',
                        position: 'bottom',
                    }}
                    viewport={[0.3, 0.25, 0.7, 0.75]}
                    isAnimated
                    dropdownTooltip={
                        <DropdownTooltip
                            dropdownCoords={dropdownCoords}
                            setToggleDropdown={setToggleDropdown}
                            toggleDropdown={toggleDropdown}
                            dropdownData={dropdownData}
                        />
                    }
                    onClick={onClick}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    hoverColor="#3040E8"
                    activeColor="#242A6B"
                />
            </div>
        </Box>
    );
}
